<template>
  <div>
    <component
      :is="icons[ambition.icon ?? ambition.parent?.icon]"
      v-if="icons[ambition.icon ?? ambition.parent?.icon]"
      class="size-16"
      :class="`fill-${ambition.color ?? ambition.parent?.color}`"
    />
    <span v-else>?</span>
  </div>
</template>

<script setup lang="ts">
import {
  IconsAmbitionsDurability,
  IconsAmbitionsEconomy,
  IconsAmbitionsFood,
  IconsAmbitionsHealth,
  IconsAmbitionsSocial,
} from '#components';
import type {Werkveld} from '~/graphql/graphql';

defineProps<{
  ambition: Werkveld
}>();

const icons = {
  'durability': IconsAmbitionsDurability,
  'economy': IconsAmbitionsEconomy,
  'food': IconsAmbitionsFood,
  'health': IconsAmbitionsHealth,
  'social': IconsAmbitionsSocial,
};
</script>
